import { createGlobalStyle } from 'styled-components';
import media from './media';

const GlobalStyle = createGlobalStyle`
  /*
    CSS RESET 
    http://meyerweb.com/eric/tools/css/reset/ 
    v2.0 | 20110126
    License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1.5;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  /* 
    FITLE GLOBAL STYLE 
  */
  html {
    color: #181818;
    font-family: 'Inter', sans-serif;

    * {
      box-sizing: border-box;
    }
  }

  strong {
    font-weight: 900;
  }

  section {
    padding: 0 1.875rem;
    margin: 100px auto;
    max-width: 1440px;
    width: 100%;
    ${media.md`
      margin: 120px auto;
    `}
    ${media.lg`
      padding: 0 60px;
    `}
    ${media.xl`
    padding: 0 120px;
    `}
  }

  span {
    margin-right: 0.2em;
    margin-left: 0.2em;
  }

  a:active {
    outline: none;
  }
`;

export default GlobalStyle;
