import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby-plugin-intl';
import { changeLocale } from 'gatsby-plugin-intl';

import { useI18n } from 'services/i18n';
import media from 'styles/media';
import Logo from 'images/fitle.svg';
import colors from 'styles/colors';

const Container = styled.footer`
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  padding: 60px 30px 120px 30px;
  display: flex;
  flex-direction: column;

  :before {
    content: '';
    background: ${colors.lighterGrey};
    position: absolute;
    left: 5%;
    height: 1px;
    width: 90%;
    top: 0;
  }

  ${media.md`
    padding: 120px;
    flex-direction: row;
  `}
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PrimaryLink = styled.div`
  a {
    flex-grow: 0;
    display: inline-block;
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #181818;
    text-decoration: none;
    :hover {
      color: #00a1ab;
    }
  }
`;

const BigPrimaryLink = styled(PrimaryLink)`
  a {
    font-size: 1rem;
    margin-top: 1.25rem;
  }
`;

const SecondaryLink = styled.div`
  a {
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    text-decoration: none;
    color: ${props => (props.selected ? '#181818' : '#818181')};
    font-weight: ${props => (props.selected ? 'bold' : 'normal')};
    :hover {
      color: #181818;
      text-decoration: underline;
    }
  }
`;

const LanguageButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  text-decoration: none;
  color: ${props => (props.selected ? '#181818' : '#818181')};
  font-weight: ${props => (props.selected ? 'bold' : 'normal')};
  :hover {
    color: #181818;
    text-decoration: underline;
  }
`;

const Title = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #4f4f4f;
  margin-bottom: 0.5rem;
`;

const Text = styled.p`
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #818181;
`;

const DescriptionContainer = styled(SubContainer)`
  order: 3;
  ${media.md`
    order: 1;
    flex: 0 1 50%;
  `}
`;

const LanguageSwitcher = styled.div`
  margin-top: 2.5rem;
  display: flex;
`;

const LinksContainer = styled(SubContainer)`
  order: 1;
  margin-bottom: 40px;
  ${media.md`
    margin: 0;
    order: 2;
    flex: 0 1 25%;
  `}
`;

const ContactContainer = styled(SubContainer)`
  margin-bottom: 60px;
  order: 2;
  ${media.md`
    margin: 0;
    order: 3;
    flex: 0 1 25%;
  `}
`;

const SocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0;
`;

const Footer = () => {
  const [t, language] = useI18n();
  return (
    <Container>
      <DescriptionContainer>
        <Logo width="150px" />
        <Text style={{ margin: '1.25rem 0', maxWidth: 360 }}>{t('footer.description')}</Text>
        <Text style={{ marginBottom: '1.25rem' }}>©{new Date().getFullYear()}, Fitle</Text>
        <SecondaryLink>
          <Link to="/terms">{t('footer.terms')}</Link>
        </SecondaryLink>
        <SecondaryLink>
          <Link to="/cookie-policy">{t('footer.cookie-policy')}</Link>
        </SecondaryLink>
        <SecondaryLink>
          <Link to="/privacy-policy">{t('footer.privacy-policy')}</Link>
        </SecondaryLink>
        <LanguageSwitcher>
          <LanguageButton
            id="ga-lang-fr"
            selected={language === 'fr'}
            onClick={() => changeLocale('fr')}
          >
            {t('footer.french')}
          </LanguageButton>
          <LanguageButton
            id="ga-lang-en"
            selected={language === 'en'}
            style={{ marginLeft: '1.25rem' }}
            onClick={() => changeLocale('en')}
          >
            {t('footer.english')}
          </LanguageButton>
        </LanguageSwitcher>
      </DescriptionContainer>
      <LinksContainer>
        <Title style={{ color: '#181818' }}>{t('footer.product')}</Title>
        <PrimaryLink>
          <Link to="/plugin">{t('footer.plugin')}</Link>
        </PrimaryLink>
        <PrimaryLink>
          <Link to="/dashboard">{t('footer.dashboard')}</Link>
        </PrimaryLink>
        <PrimaryLink>
          <a role="menuitem" href="https://www.sizerpro.com" target="blank" rel="noreferrer">
            {t('footer.sizerpro')}
          </a>
        </PrimaryLink>
        <BigPrimaryLink>
          <Link to="/integration">{t('footer.integration')}</Link>
        </BigPrimaryLink>
        <BigPrimaryLink>
          <Link to="/clients">{t('footer.clients')}</Link>
        </BigPrimaryLink>
        <BigPrimaryLink>
          <Link to="/blog">{t('footer.blog')}</Link>
        </BigPrimaryLink>
      </LinksContainer>
      <ContactContainer>
        <Title>{t('footer.contact')}</Title>
        <SecondaryLink>
          <a id="ga-link-mail" target="blank" rel="noreferrer" href="mailto:partnerships@fitle.com">
            partnerships@fitle.com
          </a>
        </SecondaryLink>
        <SecondaryLink>
          <a id="ga-link-tel" href="tel:+33185086898">
            + 33 1 85 08 68 98
          </a>
        </SecondaryLink>
        <SocialContainer>
          <SecondaryLink>
            <a
              id="ga-link-instagram"
              target="blank"
              rel="noreferrer"
              href="https://www.instagram.com/wearefitle/"
            >
              Instagram
            </a>
          </SecondaryLink>
          <SecondaryLink>
            <a
              id="ga-link-linkedin"
              target="blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/fitle/"
            >
              Linkedin
            </a>
          </SecondaryLink>
        </SocialContainer>
        <SecondaryLink>
          <a
            id="ga-link-career"
            target="blank"
            rel="noreferrer"
            href="https://www.welcometothejungle.com/fr/companies/fitle"
          >
            {t('footer.career')}
          </a>
        </SecondaryLink>
      </ContactContainer>
    </Container>
  );
};

export default Footer;
