import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby-plugin-intl';

import media from 'styles/media';
import colors from 'styles/colors';
import Arrow from 'images/icons/arrow.svg';

export const DemoLink = styled(Link).attrs(() => ({
  to: '/demo',
}))`
  border: none;
  padding: 0.5rem 3rem;
  cursor: pointer;
  background-color: ${colors.black};
  color: white;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  :hover {
    background-color: ${colors.grey};
  }
`;

export const PrimaryButton = ({ text, onClick, type = 'button', ...rest }) => (
  <DemoLink as="button" type={type} onClick={onClick} {...rest}>
    {text}
  </DemoLink>
);

const Secondary = styled(Link)`
  border: none;
  cursor: pointer;
  color: ${colors.black};
  background: transparent;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
  margin: 20px auto;
  text-decoration: none;

  svg {
    height: 0.5rem;
    margin: 0 0.3125rem 1px 0.3125rem;
    transform: ${({ $backButton }) =>
      $backButton ? 'translateX(0) rotate(180deg)' : 'translateX(0)'};
    transition: transform 250ms ease;
    #arrow {
      fill: ${colors.black};
    }
  }

  :hover {
    color: ${colors.fitle};

    svg {
      transform: ${({ $backButton }) =>
        $backButton ? 'translateX(-5px) rotate(180deg)' : 'translateX(5px)'};
      #arrow {
        fill: ${colors.fitle};
      }
    }
  }

  ${media.md`
    margin: 20px 0;
  `}
`;

export const SecondaryButton = ({ text, to, ...rest }) => (
  <Secondary to={to} {...rest}>
    <Arrow />
    {text}
  </Secondary>
);
