import { css } from 'styled-components';

export default {
  xl: (...args) => css`
    @media screen and (min-width: 1200px) {
      ${css(...args)};
    }
  `,
  lg: (...args) => css`
    @media screen and (min-width: 992px) {
      ${css(...args)};
    }
  `,
  md: (...args) => css`
    @media screen and (min-width: 768px) {
      ${css(...args)};
    }
  `,
  sm: (...args) => css`
    @media screen and (min-width: 576px) {
      ${css(...args)};
    }
  `,
};
