// This grid system is heavily influenced by react-grid-system (https://github.com/sealninja/react-grid-system)
// But this library has problems with server-side-rendering. So I rebuilt it here using CSS media queries.

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import media from './media';
import colors from './colors';

export const Background = styled.div`
  width: 100%;
  background-color: ${props => props.color || colors.lightBlueGrey};
`;

const GRID_COLUMNS = 12;

// In react-grid-system they gave max-width to the Container component.
// I don't quite know why and so far it is more a pain in the ass than anything else.
// const CONTAINER_WIDTH = {
//   sm: 540,
//   md: 750,
//   lg: 960,
//   xl: 1140,
// };
/* ${media.sm`
  max-width: ${CONTAINER_WIDTH.sm}px;
`}
${media.md`
  max-width: ${CONTAINER_WIDTH.md}px;
`}
${media.lg`
  max-width: ${CONTAINER_WIDTH.lg}px;
`}
${media.xl`
  max-width: ${CONTAINER_WIDTH.xl}px;
`} */

const StyledContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
`;

export const Container = ({ children, ...props }) => (
  <StyledContainer {...props}>{children}</StyledContainer>
);

const StyledRow = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-wrap: ${props => props.wrap};
  flex-grow: 0;
  flex-shrink: 0;
  align-items: ${props => props.align};
  justify-content: ${props => props.justify};

  ${props =>
    props.debug &&
    css`
      border: 1px solid coral;
      background-color: lightcoral;
    `}
`;

export const Row = ({ children, ...props }) => <StyledRow {...props}>{children}</StyledRow>;

Row.defaultProps = {
  debug: false,
  wrap: 'wrap',
  align: 'start',
  justify: 'flex-start',
};

Row.propTypes = {
  debug: PropTypes.bool,
  wrap: PropTypes.oneOf(['wrap', 'nowrap', 'wrap-reverse']),
  align: PropTypes.oneOf(['start', 'center', 'end', 'stretch', 'baseline', 'initial']),
  justify: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'space-between', 'space-around']),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const StyledCol = styled.div`
  align-self: ${props => props.align};
  min-height: 1px;
  position: relative;
  padding: 0 5px;
  width: ${props => props.smWidth || 'auto'};
  flex: 0 0 ${props => props.smWidth || 'auto'};
  max-width: ${props => props.smWidth || '100%'};
  ${props => props.orderSm && 'order: ' + props.orderSm + ';'} 

  ${media.md`
    width: ${props => props.mdWidth || 'auto'};
    flex: 0 0 ${props => props.mdWidth || 'auto'};
    max-width: ${props => props.mdWidth || '100%'};
    ${props => props.orderMd && 'order: ' + props.orderMd + ';'} 
    padding: 0 15px;
  `}

  ${props =>
    props.debug &&
    css`
      border: 1px solid forestgreen;
      background-color: lightgreen;
    `}
`;

const getWidth = columnsNumber => {
  if (typeof columnsNumber !== 'number') {
    return null;
  }
  const normalizedWidth = Math.max(0, Math.min(GRID_COLUMNS, columnsNumber));
  return `${(100 / GRID_COLUMNS) * normalizedWidth}%`;
};

export const Col = ({ debug, sm, md, orderSm, orderMd, align, children, ...props }) => {
  const smWidth = getWidth(sm);
  const mdWidth = getWidth(md);
  if (debug) {
    console.log({ smWidth, mdWidth, sm, md, orderSm, orderMd, align, children, ...props });
  }
  return (
    <StyledCol
      debug={debug}
      smWidth={smWidth}
      mdWidth={mdWidth}
      align={align}
      orderSm={orderSm}
      orderMd={orderMd}
      {...props}
    >
      {children}
    </StyledCol>
  );
};

Col.defaultProps = {
  debug: false,
  sm: GRID_COLUMNS,
  md: GRID_COLUMNS,
  col: GRID_COLUMNS,
  align: 'auto',
};

Col.propTypes = {
  debug: PropTypes.bool,
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['content'])]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['content'])]),
  orderSm: PropTypes.number,
  orderMd: PropTypes.number,
  align: PropTypes.oneOf([
    'auto',
    'flex-start',
    'center',
    'flex-end',
    'baseline',
    'stretch',
    'initial',
  ]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export const Hidden = styled.div`
  display: ${props => (props.sm ? 'none' : props.display)};
  ${media.md`
    display: ${props => (props.md ? 'none' : props.display)};
  `}
  ${media.lg`
    display: ${props => (props.lg ? 'none' : props.display)};
  `}
  ${media.xl`
    display: ${props => (props.xl ? 'none' : props.display)};
  `}
`;

Hidden.defaultProps = {
  display: 'block',
  sm: false,
  md: false,
  lg: false,
  xl: false,
};

Hidden.propTypes = {
  display: PropTypes.string,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  xl: PropTypes.bool,
};

export const Visible = styled.div`
  display: ${props => (props.sm ? 'block' : 'none')};
  ${media.md`
    display: ${props => (props.md ? 'block' : 'none')};
  `}
  ${media.lg`
    display: ${props => (props.lg ? 'block' : 'none')};
  `}
  ${media.xl`
    display: ${props => (props.xl ? 'block' : 'none')};
  `}
`;

Visible.defaultProps = {
  sm: false,
  md: false,
  lg: false,
  xl: false,
};

Visible.propTypes = {
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  xl: PropTypes.bool,
};
