import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby-plugin-intl';
import { useI18n } from 'services/i18n';
import Close from 'images/icons/close.svg';

const Banner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: lightgray;
  text-align: center;
  font-size: 0.625rem;
  line-height: 1;
  padding: 10px;
`;

const BannerLink = styled(Link)`
  color: black;
  font-weight: bold;
`;

// This is required because of gatsby ssr. (window will be undefined during build time)
const getStorage = () =>
  typeof window !== 'undefined' && window.localStorage ? window.localStorage : null;

const CookieBanner = () => {
  const [t] = useI18n();
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    const storage = getStorage();
    if (!!storage && !storage.getItem('cookiesAccepted')) setDisplay(true);
  }, []);

  const bannerClosed = () => {
    setDisplay(false);
    const storage = getStorage();
    if (!!storage) window.localStorage.setItem('cookiesAccepted', true);
  };

  return (
    display && (
      <Banner>
        <span>
          {t('cookie-banner.description')}{' '}
          <BannerLink style={{ textDecoration: 'underline' }} to="/cookie-policy">
            {t('cookie-banner.policy-link')}
          </BannerLink>
          <Close
            height="1rem"
            onClick={bannerClosed}
            style={{ marginLeft: 5, marginBottom: -5 }}
          ></Close>
        </span>
      </Banner>
    )
  );
};

export default CookieBanner;
