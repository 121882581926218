import { useIntl } from 'gatsby-plugin-intl';

export const useI18n = () => {
  const intl = useIntl();
  const language = intl.locale;
  const t = locale => {
    if (typeof locale === 'string') {
      return intl.formatMessage({ id: locale });
    } else if (typeof locale === 'object') {
      return intl.formatMessage({ id: locale.id }, { ...locale.values });
    }
  };

  return [t, language];
};
