import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby-plugin-intl';

import { useI18n } from 'services/i18n';
import { Visible } from 'styles/grid';
import media from 'styles/media';
import colors from 'styles/colors';
import Fitle from 'images/fitle.svg';
import Burger from 'images/icons/burger.svg';
import Carret from 'images/icons/carret.svg';
import Close from 'images/icons/close.svg';
import { DemoLink } from 'components/buttons';

const Container = styled.header`
  position: sticky;
  top: 0;
  background-color: #ffffff;
  width: 100%;
  padding: 30px 40px;
  margin: 0 auto;
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.lg`
    padding: 35px 60px;
  `}
`;

const Logo = styled(Fitle)`
  width: 154px;
  height: 40px;
  :hover {
    fill: #00a1ab;
  }
`;

const MenuContainer = styled.div`
  flex: 1;
  svg {
    cursor: pointer;
  }
`;

const PageLink = styled(Link)`
  color: #181818;
  text-decoration: none;
  margin-left: 2.5rem;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;

  :hover {
    color: #00a1ab;
  }
`;

const MobileNav = styled.nav`
  display: flex;
  justify-content: flex-end;
`;

const MobileDropdown = styled.div`
  top: 100px;
  left: 0;
  position: absolute;
  height: calc(100vh - 100px);
  width: 100%;
  background-color: white;
  z-index: 5;
  position: fixed;
  padding: 60px 30px;
  display: flex;
  flex-direction: column;
`;

const MobileLink = styled(PageLink)`
  margin: 0;
  text-decoration: underline;
`;

const MobileMenu = ({ t }) => {
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);

  useEffect(() => {
    if (isBurgerOpen) {
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('body').style.overflow = 'auto';
    }
  }, [isBurgerOpen]);

  return (
    <MobileNav>
      {isBurgerOpen ? (
        <Close height="1.5rem" onClick={() => setIsBurgerOpen(false)} />
      ) : (
        <Burger height="1rem" onClick={() => setIsBurgerOpen(true)} />
      )}
      {isBurgerOpen && (
        <MobileDropdown>
          <SolutionText style={{ marginBottom: 15 }}>{t('navbar.fitle-solutions')}</SolutionText>
          <MobileLink to="/plugin">{t('navbar.plugin')}</MobileLink>
          <SolutionText>{t('navbar.plugin-explanation')}</SolutionText>
          <MobileLink to="/dashboard">{t('navbar.dashboard')}</MobileLink>
          <SolutionText>{t('navbar.dashboard-explanation')}</SolutionText>
          <MobileLink
            as="a"
            role="menuitem"
            href="https://www.sizerpro.com"
            target="blank"
            rel="noreferrer"
          >
            {t('navbar.sizerpro')}
          </MobileLink>
          <SolutionText>{t('navbar.sizerpro-explanation')}</SolutionText>
          <MobileLink to="/retail">{t('navbar.retail')}</MobileLink>
          <SolutionText>{t('navbar.retail-explanation')}</SolutionText>
          <MobileLink to="/integration">{t('navbar.integration')}</MobileLink>
          <MobileLink to="/clients">{t('navbar.clients')}</MobileLink>
          <MobileLink to="/blog">Blog</MobileLink>
          <MobileLink
            as="a"
            role="menuitem"
            href="https://webforms.pipedrive.com/f/1CtHDfg2eKnjsHrMMo82c5CBJYjCLBkonnLwQhOEnQSY59vlHFDvddTHsdWa4hgMr"
            target="blank"
            rel="noreferrer"
          >
            {t('navbar.white-book')}
          </MobileLink>
          <DemoLink id="ga-demo-navbar-mobile" style={{ marginTop: 'auto' }}>
            {t('navbar.cta')}
          </DemoLink>
        </MobileDropdown>
      )}
    </MobileNav>
  );
};

const DesktopNav = styled.nav`
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const SolutionButton = styled.button`
  border: none;
  cursor: pointer;
  color: #181818;
  background: transparent;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 0.5rem;
  svg {
    width: 10px;
    margin-left: 0.625rem;
    transform: rotate(${props => (props.isOpen ? '180deg' : '0deg')});
    transition: transform 100ms ease;
    #carret {
      fill: #181818;
    }
  }

  :hover {
    color: #00a1ab;
    #carret {
      fill: #00a1ab;
    }
  }
`;

const SolutionContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  ul {
    background: white;
    border: 1px solid #eaeff2;
    box-shadow: 0 10px 30px 0 rgba(24, 24, 24, 0.1);
    border-radius: 6px;
    width: 291px;
    padding: 30px 40px 0 40px;
    display: flex;
    flex-direction: column;
  }
`;

const SolutionLink = styled(PageLink)`
  margin: 0;
`;

const SolutionText = styled.p`
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 300;
  color: #818181;
  margin-bottom: 30px;
`;

const DesktopMenu = ({ t }) => {
  const [isSolutionsOpen, setIsSolutionsOpen] = useState(false);
  return (
    <DesktopNav>
      <ul>
        <li
          role="none"
          style={{ position: 'relative' }}
          onMouseEnter={() => setIsSolutionsOpen(true)}
          onMouseLeave={() => setIsSolutionsOpen(false)}
        >
          <SolutionButton
            id="fitle-solutions"
            role="menuitem"
            aria-haspopup="true"
            aria-expanded={isSolutionsOpen}
            isOpen={isSolutionsOpen}
            type="button"
            onClick={() => setIsSolutionsOpen(!isSolutionsOpen)}
          >
            {t('navbar.fitle-solutions')}
            <Carret />
          </SolutionButton>
          <SolutionContainer>
            <ul
              aria-labelledby="fitle-solutions"
              style={{ display: isSolutionsOpen ? 'block' : 'none' }}
            >
              <li role="none">
                <SolutionLink role="menuitem" to="/plugin">
                  {t('navbar.plugin')}
                </SolutionLink>
                <SolutionText>{t('navbar.plugin-explanation')}</SolutionText>
              </li>
              <li role="none">
                <SolutionLink role="menuitem" to="/dashboard">
                  {t('navbar.dashboard')}
                </SolutionLink>
                <SolutionText>{t('navbar.dashboard-explanation')}</SolutionText>
              </li>
              <li role="none">
                <SolutionLink role="menuitem" to="/cross-sell">
                  {t('navbar.cross-sell')}
                </SolutionLink>
                <SolutionText>{t('navbar.cross-sell-explanation')}</SolutionText>
              </li>
              <li
                role="none"
                style={{ borderTop: `1px solid ${colors.lighterGrey}`, paddingTop: 30 }}
              >
                <SolutionLink
                  as="a"
                  role="menuitem"
                  href="https://www.sizerpro.com"
                  target="blank"
                  rel="noreferrer"
                >
                  {t('navbar.sizerpro')}
                </SolutionLink>
                <SolutionText>{t('navbar.sizerpro-explanation')}</SolutionText>
              </li>
              <li
                role="none"
                style={{ borderTop: `1px solid ${colors.lighterGrey}`, paddingTop: 30 }}
              >
                <SolutionLink role="menuitem" to="/retail">
                  {t('navbar.retail')}
                </SolutionLink>
                <SolutionText>{t('navbar.retail-explanation')}</SolutionText>
              </li>
            </ul>
          </SolutionContainer>
        </li>
        <li role="none">
          <PageLink role="menuitem" to="/integration">
            {t('navbar.integration')}
          </PageLink>
        </li>
        <li role="none">
          <PageLink role="menuitem" to="/clients">
            {t('navbar.clients')}
          </PageLink>
        </li>
        <li role="none">
          <PageLink role="menuitem" to="/blog">
            Blog
          </PageLink>
        </li>
        <li role="none">
          <PageLink
            as="a"
            role="menuitem"
            href="https://webforms.pipedrive.com/f/1CtHDfg2eKnjsHrMMo82c5CBJYjCLBkonnLwQhOEnQSY59vlHFDvddTHsdWa4hgMr"
            target="blank"
            rel="noreferrer"
          >
            {t('navbar.white-book')}
          </PageLink>
        </li>
        <li role="none">
          <DemoLink id="ga-demo-navbar-desktop" role="menuitem" style={{ marginLeft: '5vw' }}>
            {t('navbar.cta')}
          </DemoLink>
        </li>
      </ul>
    </DesktopNav>
  );
};

const Header = () => {
  const [t] = useI18n();
  return (
    <Container>
      <Link to="/" style={{ display: 'flex' }}>
        <Logo />
      </Link>
      <MenuContainer>
        <Visible sm md>
          <MobileMenu t={t} />
        </Visible>
        <Visible lg xl>
          <DesktopMenu t={t} />
        </Visible>
      </MenuContainer>
    </Container>
  );
};

export default Header;
