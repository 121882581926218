/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

// All alt, desc, title  are handled in the locales 'seo.json'. There is an exception for logos, where the 'title'
// tag is handled directly in the .svg file

function SEO({ description, lang, meta, title, markupHelper }) {
  const { site, ...data } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        illustration_fr: file(relativePath: { eq: "homepage/fr/illustration.png" }) {
          childImageSharp {
            fixed(width: 1800, height: 1800) {
              src
            }
          }
        }
        illustration_en: file(relativePath: { eq: "homepage/en/illustration.png" }) {
          childImageSharp {
            fixed(width: 1800, height: 1800) {
              src
            }
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content:
            site.siteMetadata.siteUrl + data['illustration_' + lang].childImageSharp.fixed.src,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {markupHelper && (
        // This is used for the blog articles to display the image, date and description when the article link is shared.
        // See https://support.google.com/webmasters/answer/3069489?hl=en
        <script type="application/ld+json">{`
          {
            "@context" : "${markupHelper.context}",
            "@type" : "${markupHelper.type}",
            "name" : "${markupHelper.name}",
            "datePublished" : "${markupHelper.datePublished}",
            "image" : "${markupHelper.image}"
          }
        `}</script>
      )}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  markupHelper: PropTypes.object,
};

export default SEO;
