export default {
  black: 'rgba(24, 24, 24, 1)',
  lightFitle: 'rgba(0, 161, 171, .5)',
  fitle: 'rgba(0, 161, 171, 1)',
  grey: 'rgba(61,61,61,1)',
  lightGrey: 'rgba(200, 200, 200, 1)',
  lighterGrey: 'rgba(234,239,242,1)',
  lightBlueGrey: 'rgba(248, 250, 251, 1)',
  red: 'rgba(200,62,62,1)',
};
